import { AnalyticsUser, Box, InformationDialog, useUser } from "@encompaas/common";
import { useGetAnalyticsUserQuery } from "@encompaas/common/services";
import { useEffect, useState } from "react";
import { adminBaseUrl, getLogiEndpoint } from '../utils/AnalyticsHelper';


const AdminPage = () => {
    let user = useUser(true) as AnalyticsUser;
    const [isLoading, setLoading] = useState(true);
    const [isScriptLoading, setScriptLoading] = useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const _errorTitle = "We've encountered an error.";
    const [errorValue, setErrorValue] = useState("");
    const [adminURL, setAdminURL] = useState("");
    const [tokenRefetch, setTokenRefetch] = useState(false);
    const result = useGetAnalyticsUserQuery(user);

    // Trigger for the refresh of the token every 5 minutes.
    setTimeout(() => {
         setTokenRefetch(prevCheck => !prevCheck);
    }, 300000);

    useEffect(() => {
        // Updated clause to ensure the token is the latest and hasnt timed out between the load and the render
        if (!(user === undefined) &&
            !(user.access_token === undefined)
            && result.data === undefined) {

            // If the token is newer than that of the intial load, bind the token to the URL and update.
            let newUrl = `${adminBaseUrl}${atob(user.access_token)}`;

            if (newUrl === adminURL) {
                return;
            }

            setAdminURL(newUrl);
            setLoading(prevCheck => !prevCheck);
        }
        else {
            // If the user token and the refetch both have values, issue a refetch to ensure its the latest token from Enweb.
            result.refetch();
            if (
                result.data !== undefined &&
                result.data.access_token !== user.access_token) {

                // IF the token is newer, bind it to the URL and ensure the next request doesnt fail on a token timeout.
                let newUrl = `${adminBaseUrl}${atob(result.data.access_token)}`;
                setAdminURL(newUrl);
            }
        }
    }, [user, tokenRefetch]);

    useEffect(() => {
        var isScriptPresent = document.getElementById("composer-embed-manager") ? true : false;

        if (!isScriptLoading && !isScriptPresent) {
            setScriptLoading(prevCheck => !prevCheck);
            /// Create the script header object and render on load.
            const $script = document.createElement("script");
            const $attr = document.createAttribute("data-name");
            $attr.value = "composer-embed-manager";
            const $attr2 = document.createAttribute("id");
            $attr2.value = "composer-embed-manager";
            $script.src = `${getLogiEndpoint()}embed/embed.js`;
            $script.attributes.setNamedItem($attr);
            $script.attributes.setNamedItem($attr2);
            document.head.appendChild($script);

            /// Create the script header object and render on load for the CustomJS
            const $customJS = document.createElement("script");
            $customJS.src = `${getLogiEndpoint()}api/branding/customJs.js`;
            document.head.appendChild($customJS);

            $script.onload = () => {
                window.addEventListener("load", async () => {
                    // @ts-ignore
                    window.replaceIcons();
                });

                setScriptLoading(prevCheck => !prevCheck);
            }
        }
        else {
            return;
        }
    }, [isScriptLoading]);

    if (isLoading || isScriptLoading) {
        return (
            <div>
            </div>
        );
    }
    else {
        return (
            <Box>
                <iframe
                    id="AnalyticsAdminIFrame"
                    width="fit-content"
                    height="100%"
                    style={{ border: "none" }}
                    src={adminURL}
                    title="Analytics Admin"
                    className="ecs-box ecs-background-light ecs-rounded-top ecs-box-column ecs-padding-none ecs-gap-none ecs-border-none ecs-border-color-dark ecs-box-blur"
                ></iframe>
                <InformationDialog
                    open={errorDialogOpen}
                    onClose={() => setErrorDialogOpen(false)}
                    title={_errorTitle}
                    variant={'error'}
                    information={errorValue}
                />
            </Box>
        );
    }
}

export default AdminPage;
